.custom-tabs-wrapper {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    width: 100%;
    @media (max-width: 767px) {
        margin-top: 16px;
    }
    .poll-tab {
        background: none;
        border-bottom: 3px solid #fff;
        font-family: 'Graphik';
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #333333;
        margin: 0 17px;
        cursor: pointer;
    }
    .poll-tab-active {
        background: none;
        border-bottom: 3px solid #4D1B6C;
        color: #4D1B6C;
    }
}

.announcement__width {
    max-width: 1300px !important;
}

.card-scrollbar {
    // max-height: 330px;
    // overflow-y: auto;
    margin-bottom: 35px;
  -webkit-overflow-scrolling: touch; 
}
.card-scrollbar:empty {
    margin-bottom: 0;
}

.paused {
    animation-play-state: paused;
  }

// .dynamic-content {
//     animation: scrollAnimation 400s linear infinite;
//   }
  
//   @keyframes scrollAnimation {
//     0% {
//       transform: translateY(0);
//     }
//     100% {
//       transform: translateY(calc(-100% + 200px)); /* Adjust the value based on the container height */
//     }
//   }

  .outer-scroll {
     // height: calc(100vh - 184px);
     height: calc(100vh - 667px);
     overflow-y: auto;
     padding-right: 6px;
     -webkit-overflow-scrolling: touch;
     @media (max-width:1620px) {
         height: calc(100vh - 467px);
     }
  }
  .other-news-height {
    height: calc(100vh - 270px);
  }
// .outer-scroll::-webkit-scrollbar {
//     width: 0;
//     height: 0;
// }
// .outer-scroll::-webkit-scrollbar-button {
//     background: #ccc;
// }
// .outer-scroll::-webkit-scrollbar-track-piece {
//     background: #888;
// }
// .outer-scroll::-webkit-scrollbar-thumb {
//     background: #eee;
// }

.autoscroll-sec::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.autoscroll-sec::-webkit-scrollbar-button {
    background: #ccc;
}
.autoscroll-sec::-webkit-scrollbar-track-piece {
    background: #888;
}
.autoscroll-sec::-webkit-scrollbar-thumb {
    background: #eee;
}



// .card-scrollbar::-webkit-scrollbar-button {
//     background: #ccc;
// }
// .card-scrollbar::-webkit-scrollbar-track-piece {
//     background: #888;
// }
// .card-scrollbar::-webkit-scrollbar-thumb {
//     background: #eee;
// }


.main__wrapper--left {
    display: inline-block;
    vertical-align: top;
    @media (max-width:1024px) {
        width: 100% !important;
        max-width: 100% !important;
        display: block !important;
        overflow-x: hidden;
    }
}
.main__wrapper--right {
    display: inline-block;
    margin-left: 30px;
    width: calc(100% - 430px) !important;
    max-width: 430px !important;
    // height: 100vh;
    position: sticky;
    top: 102px;
    @media (max-width:1299px) {
        margin-left: 0px;
    }
    @media (max-width:1024px) {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0px;
        height: auto;
        display: block !important;
        position: static !important;
    }
}
.autoscroll-sec {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .gray-card-sticky {
        position: sticky;
        top: 0;
        // height: calc(100vh - 60px);
        margin-bottom: 0 !important;
    }
}

.main__wrapper {
    margin: 0 auto 40px;
    max-width: 1300px;
    width: 100%;

    .feed__container {
        margin-left: 0;
        padding: 0;
        width: calc(100% - 430px);
        border: 1px solid #D8D8D8;

        @media (max-width:767px) {
            width: 100%;
        }

        .feed__container__heading {
            background: #F8F8F8;
            text-align: center;
            margin: 0 0 26px 0;
            padding: 18px 14px;

            h2 {
                color: #333;
                font-family: Georgia;
                font-size: 24px;
                font-weight: 700;
                line-height: 120%;
                margin: 0 auto;
                // overflow: hidden;
                // max-width: 100%;
                // text-overflow: ellipsis;
                // width: fit-content;
                // white-space: nowrap;
                text-align: center;
                // padding-left: 60px;
                // padding-right: 60px;

                @media (max-width:767px) {
                    // padding-left: 30px;
                    // padding-right: 30px;
                    font-size: 20px;

                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: pre-line;
                }
            }
        }

        .feed__item {

            // padding-left: 30px;
            // padding-right: 30px;
            .feed__header {
                padding-left: 30px;
                padding-right: 30px;

                @media (max-width:767px) {
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }

            .feed__thumb {
                margin-left: 30px;
                margin-right: 30px;

                @media (max-width:767px) {
                    margin-left: 16px;
                    margin-right: 16px;
                }
            }

            .feed__desc {
                padding-left: 30px;
                padding-right: 30px;

                @media (max-width:767px) {
                    padding-left: 16px;
                    padding-right: 16px;
                }
            }
        }
    }

    .home__sidebar {
        display: inline-block;
        max-width: 400px;
        width: 100%;
        // position: sticky;
        // bottom: 0;
        // top: -260px;
        // margin-left: 30px;

        @media (max-width:767px) {
            width: 100% !important;
            max-width: 100% !important;
            position: static;
            height: auto;
            margin-left: 0;
        }

        .short-otherNews {
            span {
                color: #333;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                margin: 0 0 24px 0;
                padding: 0;
                // font-family: "Graphik";
                display: block;

                @media (max-width:767px) {
                    font-size: 14px;
                }

                a {
                    color: #4D1B6C;
                    text-decoration-line: underline;
                }

            }
        }
       .box-border {
        border: 1px solid #ccc;
        padding: 20px;
        background: #F8F8F8;
        }

        .lightgray-bg {
            background: #F8F8F8;
        }

        .gray-card {
            border: 1px solid #F8F8F8;
            // background: #F8F8F8;
            
            
            padding: 20px 0px 20px 0;
            min-height: 200px;
            margin-bottom: 36px;
            

            @media (max-width:767px) {
                padding-left: 16px;
                padding-right: 16px;
            }
            &:first-child {
                padding-top: 0;
              
            }

            h3 {
                color: #333;
                font-family: Georgia;
                font-size: 22px;
                font-weight: 700;
                line-height: 120%;
                margin: 0 auto 26px;
                overflow: hidden;
                max-width: 100%;
                // text-overflow: ellipsis;
                // width: fit-content;
                // white-space: nowrap;
                text-align: center;
                // padding-left: 70px;
                // padding-right: 70px;

                @media (max-width:767px) {
                    // padding-left: 30px;
                    // padding-right: 30px;
                    font-size: 20px;
                }
            }

            h6 {
                color: #333;
                font-family: Georgia;
                font-size: 16px;
                // font-style: italic;
                font-weight: 700;
                line-height: 120%;
                margin: 0 0 12px 0;
                text-align: center;
            }

            p {
                color: #333;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0 0 24px 0;
                padding: 0;
                font-family: "Graphik";


                @media (max-width:767px) {
                    font-size: 14px;
                }

                a {
                    color: #4D1B6C;
                    text-decoration-line: underline;
                }
            }

            .divider-line {
                border-bottom: 1px solid #DADADA;
                margin: 24px 0;
            }

            .graph-box {
                // border: 1px solid #000;
                background: #FFF;
                width: 100%;
                // height: 322px;
                margin-bottom: 24px;

                img {
                    max-width: 100%;
                    width: 100%;
                }
            }

            .anchor-links {
                color: #4D1B6C;
                font-size: 16px;
                font-weight: 600 !important;
                line-height: normal;
                text-decoration-line: underline;
                display: block;
            }

            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                position: absolute;
                left: -9999px;
            }

            [type="radio"]:checked+label,
            [type="radio"]:not(:checked)+label {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                color: #333;
                font-family: "Graphik";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;

            }

            [type="radio"]:checked+label:before,
            [type="radio"]:not(:checked)+label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 20px;
                height: 20px;
                border: 1px solid #000;
                border-radius: 100%;
                background: #fff;
            }

            [type="radio"]:checked+label:after,
            [type="radio"]:not(:checked)+label:after {
                content: '';
                width: 20px;
                height: 20px;
                background: #4D1B6C;
                border: 1px solid #4D1B6C;
                position: absolute;
                top: 0px;
                left: 0px;
                border-radius: 100%;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }

            [type="radio"]:not(:checked)+label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }

            [type="radio"]:checked+label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }

            .news-list-scroll {
                // max-height: 340px;
                // overflow-y: auto;
                max-height: calc(100vh - 230px);;
                overflow-y: auto;
                @media (max-width:767px) {
                    max-height: auto;
                }
            }
            .current-date-news {
                color: #333;
                font-family: Georgia;
                font-size: 16px;
                font-style: normal;
                font-weight: bold;
                line-height: 119%; /* 19.2px */
                width: 100%;
                border-top: 1px solid #000;
                border-bottom: 1px solid #000;
                padding: 8px 20px;
                margin: 20px 0 0 0;
                position: sticky;
                top: 0;
                background: #fff;
                z-index: 99;
            }

            .news-list-item {
                display: flex;
                align-items: flex-start;
                margin-bottom: 24px;
               
                .short-otherNews {
                    position: relative;
                    padding-top: 16.6%;
                    width: 82px;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        border-radius: 10px;
                        
                    }
                }
                span {
                    width: calc(100% - 82px);
                    padding-left: 10px;
                    font-size: 16px;
                    line-height: normal;
                    color: #333333;
                    @media (max-width:767px) {
                        font-size: 14px;
                    }
                    a {
                        color: #4D1B6C;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.succes-msg-green {
    color: red !important;
    font-weight: 500 !important;
    margin-top: -10px !important;
}

.min-ht-52 {
    min-height: 52px;
}

.correct-answer {
    color: #00C599 !important;
    font-weight: 600 !important;
}

.wrong-answer {
    color: #F04533 !important;
    font-weight: 600 !important;
}

.correct-anser-label {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #000 !important;
    margin: 0 0 16px 0 !important;
}

.option-label {
    font-size: 12px;
}

.progressbar-gray {
    background-color: #D8D8D8;
    width: 352px;
    height: 11px;
    position: relative;
    margin-bottom: 20px;

    .progressbar-purple {
        background-color: #4D1B6C;
        position: absolute;
        left: 0;
        top: 0;
        height: 11px;
    }
}
.sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
}
.viewmore-news {
    max-width: 430px;
    width: 100%;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    background: #4D1B6C;
    border: 1px solid #4D1B6C !important;
    border-radius: 0;
    font-family: 'Graphik';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    min-height: 60px;
    padding: 0 16px;
    position: sticky;
    right: 0;
    bottom: 0;
    margin-top: -60px;
}


