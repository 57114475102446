@import 'antd/dist/antd.css';
@import "./variable.scss";
@import "./fonts.scss";

// Bootrsrap Overwrite CSS
@media (min-width: 1200px) {
  .container {
    max-width: $maxWidth;
  }
}


.successful-card {
  background: #f7f7f7;
  min-height: 100vh;
  padding: 24px;
  .email-obfuscate {
    font-weight: 700;
    font-size: 20px;
    color: #666;
  }
  .heading-main {
    font-weight: 700;
    font-size: 40px;
    color: #c94d3f;
  }
  .red-color {
    color: #c94d3f;
  }
  .heading-submain {
    font-weight: 700;
    font-size: 22px;
    color: #000;
    margin: 40px auto 30px;
    max-width: 100%;
    width: 640px;
  }
  .border-card {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 40px 60px;
    background: #fff;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  .graytext {
    font-size: 16px;
    color: #666;
    font-weight: 500;
  }
  .border-card-td {
    border-bottom: 1px solid #ccc;
  }
  .btnwrap {
    border-top: 1px solid #ccc;
    margin-top: 100px;
    .viewbtn {
      margin-top: 40px;
      border: 2px solid #ccc;
      background: #fff;
      font-weight: 600;
      font-size: 18px;
      color: #ccc;
      padding: 10px 30px;
    }
  }
}

.custom__container {
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;
}

a {
  color: #030dcf;
}

// Bootsrap overwrite

.form-group {
  margin-bottom: 22px;
}

.form-control {
  border: 1px solid #D9DCE1;
  border-radius: 0.3125rem;
  color: $black;
  font-size: $fontbase;
  min-height: calc(1.5em + 0.75rem + 0.5rem);

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &:focus {
    box-shadow: none;
    border-color: $gray;
  }

  &.form-control-flat {
    border-radius: 0;
  }
}

textarea.form-control {
  padding: 0.9375rem 0.75rem;
  resize: none;
}

select,
select.custom-select {
  appearance: none;
  background: $white url(../images/select-icon.svg) no-repeat;
  background-position: calc(100% - 15px) center;
  padding-right: 30px;
}

.form-control-note {
  font-style: italic;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 16px;
  color: #979797;
  margin-top: 0.3125rem;
}

.form-control-normal-note {
  font-size: 14px;
  line-height: 17px;
  color: #1A1F36;
}

.invalid-feedback {
  display: block;
  color: $red;

  &:empty {
    display: none;
  }
}

.success-feedback {
  display: block;
  font-size: 14px;
  color: $green;
  width: 100%;
  margin-top: 0.25rem;

  &:empty {
    display: none;
  }
}

.label-primary {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1A1F36;
  margin-bottom: 10px;

  .required {
    color: $red;
  }
}


.btn {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  padding: 0.9375rem .75rem;

  &:hover,
  &:focus {
    box-shadow: none;
    opacity: .90;
  }

  &:disabled {}
}

.btn__icon {
  margin-right: 0.5rem;
}

.btn-sm {
  padding: 0.9375rem 1rem;
}

.btn-lg {
  font-size: 1rem;
  padding: 1.125rem 1rem;
}

.btn__redius {
  border-radius: 4px;
}

.btn__purple {
  color: $white;
  background-color: $purple;
  border-color: $purple;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background: $purple;
    border-color: $purple;

  }
}

.btn__white {
  color: $purple;
  background-color: $white;
  border-color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $purple;
    background: $white;
    border-color: $white;

  }
}

.btn__purple--outline {
  color: $purple;
  background-color: $white;
  border-color: $white;


  &:hover,
  &:focus,
  &:active {
    color: $purple;
    background-color: $white;
    border-color: $white;

  }
}

.btn__default {
  color: #3C4257;
  background-color: $white;
  border-color: $white;


  &:hover,
  &:focus,
  &:active {
    color: #3C4257;
    background-color: $white;
    border-color: $white;

  }
}

.btn__link {
  color: $purple;
  text-decoration: none;
  padding: 0 0.5rem;

  &:hover,
  &:focus,
  &:focus {
    color: $purple;
  }
}

.btn__link--underline {
  color: $purple;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:focus {
    color: $purple;
    text-decoration: underline;
  }
}

.btn__link--deafult {
  color: $black;
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: $black;
    text-decoration: none;
  }
}

.btn__link--danger {
  color: $red;
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus {
    color: $red;
    text-decoration: none;
  }
}


.card {
  border: 0;
  box-shadow: 5px 5px 20px #E5E5E5;
  border-radius: 6px;
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  &.none-shadow {
    box-shadow: none;
  }

  &.transparent {
    background-color: transparent;
  }

  .card-header {
    padding: 12px 25px;
    background-color: #fff;
    border-bottom: 1px solid #E5E5E5;
    min-height: 65px;
    border-radius: 6px 6px 0 0;

    &.card-header-lg {
      padding: 12px 45px;

      @media (max-width: 767px) {
        padding: 12px 15px;
      }
    }

    .card-title {
      font-weight: $bold;
      font-size: 22px;
      line-height: 26px;
      color: $black;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 18px;
      }

      &.card-title-sm {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  .card-body {
    padding: 20px 45px;

    @media (max-width: 767px) {
      padding: 20px 15px;
    }

    &.card-body-sm {
      padding: 25px;

      @media (max-width: 767px) {
        padding: 15px;
      }
    }
  }
}

// Bootsrap overwrite

// Base 
.cursor {
  cursor: pointer;
}

.underline {
  text-decoration: underline !important;
}

// Common Css

// Common Css

// Loader
.anticon-spin {
  animation: loadingCircle .6s infinite linear;
}

.loader__full {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 10;
  width: 100%;

  &::after {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  // position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  &.loader__cover {
    position: absolute;
  }
}

// Loader

// Slick orverwrite
.slick-arrow {
  z-index: 1;

  &.slick-prev,
  &.slick-next {
    width: 60px;
    height: 60px;

    &::before {
      font-family: inherit;
      font-size: inherit;
      opacity: 1;
    }
  }

  &.slick-prev {
    left: 0;
    background: url('../images/slick-left.svg') no-repeat;
    background-size: cover;
  }

  &.slick-next {
    right: 0;
    background: url('../images/slick-right.svg') no-repeat;
    background-size: cover;
  }
}

// Slick orverwrite

// Custom Checkbox
.custom__checkbox {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.08em;
  color: #333333;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom__checkbox--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.custom__checkbox--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 0;
  background-color: #fff;
  border: 1px solid rgba(77, 27, 108, 0.5);
}

.custom__checkbox {
  .custom__checkbox--input:checked~.custom__checkbox--check {
    background-color: $purple;
  }
}

.custom__checkbox--check:after {

  content: "";
  position: absolute;
  display: none;
}

.custom__checkbox {
  .custom__checkbox--input:checked~.custom__checkbox--check:after {
    display: block;
  }

  .custom__checkbox--check:after {
    top: 3px;
    left: 7px;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Custom Checkbox 

// Custom Checkbox Only
.custom__checkbox--only {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1A1F36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 14px;
  height: 14px;

  .custom__checkbox--only-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:disabled {
      pointer-events: none;
      opacity: .8;
    }
  }
}

.custom__checkbox--only-check {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #dadbde;
  // box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
  // 0px 0px 0px 1px rgba(60, 66, 87, 0.16),
  // 0px 1px 1px rgba(0, 0, 0, 0.12);
}

.custom__checkbox--only {
  .custom__checkbox--only-input:checked~.custom__checkbox--only-check {
    background-color: $purple;
    border: 1px solid $purple;
  }
}

.custom__checkbox--only-check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__checkbox--only {
  .custom__checkbox--only-input:checked~.custom__checkbox--only-check:after {
    display: block;
  }

  .custom__checkbox--only-check:after {
    left: 4px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

// Custom Checkbox Only

// Radio
.custom__radio {
  display: inline-block;
  position: relative;
  padding-left: 27px;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1A1F36;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .custom__radio--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}

.custom__radio--check {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: $white;
  border: 1px solid #dadbde;
  border-radius: 50%;
}

.custom__radio {

  .custom__radio--input:checked~.custom__radio--check {
    background-color: $white;
  }
}

.custom__radio--check:after {
  content: "";
  position: absolute;
  display: none;
}

.custom__radio {
  .custom__radio--input:checked~.custom__radio--check:after {
    display: block;
  }

  .custom__radio--check:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $purple;
  }
}

// Radio

// Radio Btn group
.custom__radio__group {
  position: relative;
  display: inline-block;

  .custom__radio__group--button {
    appearance: none;
    opacity: 0;
    position: absolute;
    z-index: -9999;

    &:checked+.custom__radio__group--label {
      color: $white;
      background: $purple;
    }
  }

  .custom__radio__group--label {
    font-weight: 500;
    font-size: 0.875rem;
    padding: 0.625rem 1.75rem;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #6D757E;
    background: #E5E5E5;
    border-radius: 4px;
    margin-right: 15px;
    transition: all 0.2s ease;
    @media(max-width: 767px){
      margin-right: 7px;
      padding: .625rem 1.35rem;
    }
  }
}

// Radio Btn group

// Antd Css overwrite
.ant-notification {
  .cm-notification {
    .ant-notification-notice-description {
      padding-right: 20px;
    }
  }
}

// Antd Css overwrite 


// Impersonate
.impersonate {
  background: #F7D9FF;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .impersonate__container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .impersonate__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #C800FF;
    text-align: center;

    .impersonate__link {
      color: #C800FF;
      font-weight: 500;
      font-size: 14px;
      background: transparent;
      background-image: none;
      border: 0;
      padding: 0;
      text-decoration: underline;
    }
  }
}

// Impersonate

// Main
$header: 57px;
$impersonate: 50px;
$tabs: 38px;

.main {
  &[role="header"] {
    padding-top: 57px;
  }

  &[role="header-with-tabs"] {
    padding-top: 104px;

    &[impersonate="impersonate"] {
      padding-top: 104px + 50px;
    }
  }

  &[role="head"] {
    padding-top: 66px;
  }

  &[role="tabs"] {
    padding-top: 104px;
  }

  .main__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
}

// Main


// Base 

// React Modal Css
.ReactModal__Body--open {
  overflow: hidden;
  // padding-right: 17px;
}

.ReactModal__Body--open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open.modal {
  background: rgba(0, 0, 0, 0.5);
}

.ReactModal__Overlay--after-open.modal {
  display: block;
}


.react-modal {
  .modal-content {
    border: 0;
    border-radius: 10px;
  }

  .modal-dialog {
    max-width: 600px;

    @media (min-width: 576px) {
      margin: 1rem auto;
    }

    &.react-modal-dialog-xs {
      max-width: 420px;
    }

    &.react-modal-dialog-sm {
      max-width: 700px;
    }

    &.react-modal-dialog-sm-md {
      max-width: 850px;
    }

    &.react-modal-dialog-md {
      max-width: 960px;
    }

    &.react-modal-dialog-lg {
      max-width: 1024px;
    }

    &.react-modal-dialog-xl {
      max-width: 1200px;
    }
  }
}

.react-modal-header {
  padding: 20px;
  position: relative;

  .react-modal-title {

    font-weight: $bold;
    font-size: 16px;
    line-height: 22px;
    color: $lightblack;
    margin-bottom: 0;
  }

  .react-modal-subtitle {
    font-size: 16px;
    line-height: 25px;
    color: $black;
    margin-bottom: 0;
  }

  .react-modal-close {
    position: absolute;
    top: 8px;
    right: 15px;
    z-index: 1;
  }
}

.react-modal-body {
  padding: 20px;
  background: #F7FAFC;
}

.react-modal-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

.react-modal-body-text {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.react-modal-footer {
  padding: 20px;
  border-radius: 0 0 10px 10px;
  background: $white;
  display: flex;
  justify-content: flex-end;
}

// React Modal Css

// Genral Css

// material-Lable style
.material-textfield {
  position: relative;

  .material-textfield-label {
    font-size: 14px;
    // line-height: normal;
    color: #000000;
    position: absolute;
    pointer-events: none;
    left: 14px;
    top: 16px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    margin-bottom: 0;
  }

  .material-textfield--input {

    &:focus,
    // &:read-only,
    &:disabled,
    &:valid {
      padding-top: 20px;
    }

    // &:read-only ~ .material-textfield-label,
    &:disabled~.material-textfield-label,
    &:valid~.material-textfield-label,
    &:focus~.material-textfield-label {
      top: 8px;
      left: 14px;
      font-weight: $bold;
      font-size: 12px;
      // line-height: normal;
      color: $black;
    }

    &:disabled~.material-textfield-label {
      color: #9B9B9B;
      top: 8px;
      font-size: 14px;
      // line-height: normal;
      font-weight: 700;
    }
  }
}

// material-Lable style

// Dropzone
.dropzone {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  .dropzone__area {
    background: $white;
    border: 1px dashed #D9DCE1;
    border-radius: 5px;
    width: 100%;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.dropzone__grid {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  .dropzone__grid--sorter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .dropzone__grid--item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    width: 50px;
    height: 50px;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }
}

.dropzone__grid--preview {
  position: relative;
  width: 100%;
  height: 50px;
  background: #d2d6dc;
  border-radius: 4px;

  >.preview__thumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    display: block;
    // position: absolute;
    // top: 0;
    // left: 0;
  }
}

.dropzone__grid--remove {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  .btn__remove {
    line-height: normal;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    padding: 4px;
    border-radius: 100px;
    position: absolute;
    z-index: 1;
    background: #f04533;

    // background: #000;
    svg {
      position: absolute;
      z-index: -1;
      pointer-events: none;
    }
  }
}

// Dropzone

// Header
.top__navigation {
  display: flex;
  align-items: center;
  position: relative;
  background: #fff;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid #DADADA;

  @media (min-width: 768px) {
    padding: 0;
    border-bottom: 0;
  }

  &.top__navigation--fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
}

.top__navigation--container {
  width: calc(100% - 30px);
  max-width: $maxWidth;
  margin: 0 auto;

  @media (min-width: 1200px) {
    max-width: 1920px;
  }
}

.top__navigation--row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 768px) {
    padding: 11px 0;
    border-bottom: 1px solid #DADADA;
  }

  .top__navigation--left {
    display: flex;
    align-items: center;

    .top__navigation--hamburger {
      @media (min-width: 1024px) {
        display: none;
      }

      .btn__hamburger {
        border-radius: 0;
      }
    }

    .top__navigation--logo {
      margin-right: 25px;

      .brand {
        display: inline-flex;
        padding: 0;
        border: none;
        background: none;
      }
    }
  }

  .top__navigation--right {
    .btn__subscribe {
      min-width: 120px;
      display: none;

      @media (min-width: 1023px) {
        min-width: 200px;
        display: block;
      }
    }
  }
}

.top__navigation--nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;

  @media (min-width: 1023px) {
    display: flex;
    align-items: center;
  }

  >li {
    margin-left: 35px;

    &:first-child {
      margin-left: 0;
    }
  }

  .nav__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0.3125rem 0;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #333333;
    text-decoration: none;
    border: 0;
    background-color: transparent;
    position: relative;

    &:hover,
    &:focus {
      color: $purple;
    }

    &.active {
      color: $purple;

      &::after {
        content: '';
        height: 3px;
        width: 100%;
        background: $purple;
        display: block;
        position: absolute;
        left: 0;
        bottom: -30px;
      }
    }
  }
}

.top__navigation--dropdown {
  .profile__wrapper {
    padding: 12px 22px;
    border-bottom: 1px solid #D9DCE1;
    min-width: 200px;

    .profile__name {
      color: $black;
      font-size: 14px;
      font-weight: 600;

    }

    .profile__role {
      font-size: 12px;
    }
  }





  .top__navigation--dropdown-link,
  .top__navigation--dropdown-btn {
    text-decoration: none;
    font-size: 14px;
    color: $purple;
    font-weight: 600;

    &:hover {
      color: $purple;
    }
  }

}

.drawer__nav--drawer {
  .ant-drawer-content {
    background-color: $purple;

    .ant-drawer-header {
      display: none;
    }
  }
}

.drawer__nav--header {
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 1;

  .drawer__nav--close {
    color: $white;
  }
}

.drawer__nav--container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  .drawer__nav--wrap {
    .drawer__nav {
      @include list-style;

      >li {
        margin-bottom: 15px;
      }

      .nav__link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0.3125rem 0;
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        position: relative;
        border: 0;
        background-color: transparent;

        &:hover,
        &:focus {
          color: $white;
        }

        &.active {
          color: $white;

          &::after {
            content: '';
            height: 3px;
            width: 100%;
            background: $white;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
  }

  .drawer__nav--footer {}
}

// Header

.main {
  padding-top: 86px;
}

// Announcement
.announcement {
  padding: 25px 15px;

  @media (min-width: 768px) {
    padding: 35px 15px;
  }

  .announcement__row {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    width: 100%;
    max-width: 810px;
    margin: 0 auto;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .announcement__left {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (min-width: 768px) {
      padding: 15px 35px;
    }

    .announcement__left--inner {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      >img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .announcement__right {
    padding: 15px;

    @media (min-width: 768px) {
      padding: 15px 25px 15px 0;
    }

    .announcement__right--content {
      .announcement__right--content-text {
        font-family: $Georgia;
        font-weight: 400;
        font-style: italic;
        font-size: 14px;
        color: #333333;
        text-align: center;
        margin-bottom: 10px;

        @media (min-width: 768px) {
          font-size: 18px;
          text-align: left;
        }
      }
    }
  }

  .announcement__author {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    text-align: center;

    @media (min-width: 768px) {
      text-align: right;
    }

    .announcement__author--divider {
      padding-right: 8px;
    }
  }
}

// Announcement

// Feed
.feed__container {
  max-width: 840px;
  margin: 0 auto;
  padding: 0 15px;
}

.feed__row {}

.feed__item {
  display: block;
  border-bottom: 1px solid #DADADA;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 35px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .feed__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    @media (min-width: 768px) {
      margin-bottom: 22px;
    }
  }

  .feed__title {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #333333;
    margin-bottom: 0;

    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 120%;
    }
  }

  .feed__download {
    display: inline-block;
  }

  .feed__thumb {
    position: relative;
    padding-top: 64%;
    background: #F4F4F4;
    border-radius: 10px;
    display: block;
    height: 0;

    @media (min-width: 768px) {
      border-radius: 20px;
      padding-top: 490px;
    }

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 10px;
      position: absolute;
      top: 0;
      left: 0;

      @media (min-width: 768px) {
        border-radius: 20px;
      }
    }
  }

  .feed__desc {
    padding: 22px 0;

    .feed__desc--links {
      @include list-style;
      font-family: $Georgia;
      padding-left: 0;

      >li {
        font-size: 14px;

        @media (min-width: 768px) {
          font-size: 16px;
        }

        &::marker {
          color: $purple;
        }

        >a {
          font-family: $Georgia;
          font-weight: 400;
          font-size: 14px;
          line-height: normal;
          text-decoration: underline;
          color: #4D1B6C;
          display: inline-block;
          padding: 5px 0;

          @media (min-width: 768px) {
            font-size: 16px;
            line-height: normal;
          }
        }
      }

      .feed__desc--links-child {
        font-family: $Georgia;
        padding-left: 14px;

        @media (min-width: 768px) {
          padding-left: 20px;
        }

        >li {
          font-size: 14px;

          @media (min-width: 768px) {
            font-size: 16px;
          }

          &::marker {
            color: $purple;
          }

          >a {
            font-family: $Georgia;
            font-weight: 400;
            font-size: 14px;
            line-height: normal;
            text-decoration: underline;
            color: #4D1B6C;
            display: inline-flex;
            padding: 5px 0;

            @media (min-width: 768px) {
              font-size: 16px;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

.read-more-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 12px;

  .feed__editor {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #333333;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
    p {
      color: #333333;
      a {
        color: #4D1B6C;
        text-decoration: underline;

        &:hover {
          color: #4D1B6C;
        }
      
      }
    }
  }

  .read-more-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    color: $purple;
    display: inline;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.feed__footer {
  padding: 35px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    width: 100%;
    max-width: 505px;
  }
}

.section__feed--detail {
  padding: 0 0 40px;
  word-wrap: break-word;

  @media (min-width: 768px) {
    padding: 40px 0;
  }
}


.feed__main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.feed__left {
  width: 100%;
  max-width: 390px;
  display: none;

  @media (min-width: 1024px) {
    display: block;
    // border-right: 1px solid #DADADA;
    // padding-right: 60px;
  }
}

.feed__right {
  width: calc(100%);

  @media (min-width: 1024px) {
    width: calc(100% - 390px);
    padding-left: 50px;
  }
}

.feed__left--wrapper {
  max-height: calc(100vh - 134px);
  overflow: auto;

  @media (min-width: 1024px) {
    border-right: 1px solid #DADADA;
    padding-right: 50px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #DADADA;
  }
}

.feed__left--wrapper.sticky-top {
  top: 134px;
  z-index: 10;
}

.feed__action--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;

  @media (min-width: 768px) {
    display: none;
  }

  .feed__action--btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-decoration: underline;
    color: $purple;
    display: inline-block;

    &.btn__full-report {
      svg {
        margin-right: 10px;
      }
    }

    &.btn__copy-url {}
  }
}

.article__content {
  @media (min-width: 768px) {
    max-width: 810px;
  }
}

.article__intro {
  padding: 0;
}

.article__featured {
  position: relative;
  margin-bottom: 20px;
  border-radius: 20px;
  padding-top: 66.66%;
  background: #f4f4f4;

  @media (min-width: 768px) {
    padding-top: 490px;
  }

  >img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 20px;
  }
}

.article__intro--editor {
  .article__editor {}

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #333333;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 26px;
    }

    a {
      color: #4D1B6C;

      &:hover {
        color: #4D1B6C;
      }
    }

    span {
      font-weight: 400;
      font-size: 14px !important;
      line-height: 22px;
      color: #333333;

      @media (min-width: 768px) {
        font-size: 16px !important;
        line-height: 26px;
      }
    }
  }

  ol {
    padding-left: 20px;

    li {
      color: #333333;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      @media (min-width: 768px) {
        font-size: 16px;
        line-height: 26px;
      }

      &::marker {
        color: $purple;
      }

      >a {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        color: #4D1B6C;

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }

}

.article__editor {
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #333333;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 26px;
    }

    a {
      color: #4D1B6C;

      &:hover {
        color: #4D1B6C;
      }
    }

    b,
    strong {
      font-weight: 500;
    }
  }

  ol,
  ul {
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 16px;
    }

    li {
      a {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-decoration: underline;
        color: #4D1B6C;

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }

  img {
    max-width: 100%;
  }
}

.article__editor--other-news {
  h4 {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 15px;
  }

  li span {
    display: inline-block;
    vertical-align: top;
  }
}

.article__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border-top: 1px solid #DADADA;
  padding-top: 30px;
  margin-top: 30px;
}

.article__title {
  font-family: $Georgia;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
  text-decoration: none;
  margin-bottom: 0;

  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 150%;
  }

  // &.active,
  // &:hover {
  //   color: $purple;
  // }
  // &.isCurrent {
  //   color: $purple;
  // }
}

.article__btn-copy {
  padding: 10px;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.feed__contant--nav-mobile {
  padding: 10px 0;

  @media (min-width: 768px) {
    display: none;
  }

  .feed__contant--nav--btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #4D1B6C;
    display: flex;
    align-items: center;
    padding: 0.375rem 0;

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 27px;
    }

    &:hover,
    &:focus {
      color: #4D1B6C;
    }
  }
}

.feed__contant--nav {
  display: none;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #DADADA;
    padding: 15px 0;
  }

  .feed__contant--nav--btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #4D1B6C;
    display: flex;
    align-items: center;
    padding: 0.375rem 0;

    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 27px;
    }

    &:hover,
    &:focus {
      color: #4D1B6C;
    }

    .feed__contant--nav-icon {
      &.left-icon {
        margin-right: 10px;
      }

      &.right-icon {
        margin-left: 10px;
      }
    }
  }
}

.floating__nav--wrap {}

.floating__nav--title {
  font-family: $Georgia;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #333333;

  @media (min-width: 768px) {
    font-size: 20px;
  }
}

.floating__nav--divider {
  display: flex;
  width: 55px;
  height: 4px;
  background: #333333;
  margin: 12px 0 20px;
}

.floating__nav {
  @include list-style;

  >.floating__nav--item,
  .floating__nav--child-item {
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;

    &:hover .floating__nav--count {
      color: #4D1B6C;
    }

    &.isCurrent {

      >.floating__nav--link,
      .floating__nav--child-link {
        color: #4D1B6C;
      }

      .floating__nav--count {
        color: #4D1B6C;
      }
    }

    >.floating__nav--link,
    .floating__nav--child-link {
      font-family: $Georgia;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline;
      color: #979797;
      position: relative;
      display: inline-flex;

      @media (min-width: 768px) {
        font-size: 16px;
      }

      &:hover {
        color: #4D1B6C;
      }

      // &.isCurrent {
      //   color: #4D1B6C;
      // }
    }

    .floating__nav--count {
      font-family: $Georgia;
      font-size: 14px;
      line-height: 18px;
      color: #979797;
      margin-right: 2px;
      text-decoration: none;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
  }
}

.floating__nav--child {
  // @include list-style;
  padding-left: 20px;

  >.floating__nav--child-item {
    font-family: $Georgia;
    margin-bottom: 10px;

    &::marker {
      color: #979797;
    }

    >.floating__nav--child-link {
      font-family: $Georgia;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-decoration: underline;
      color: #979797;

      @media (min-width: 768px) {
        font-size: 16px;
      }

      &:hover,
      &.active {
        color: #4D1B6C;
      }
    }
  }
}

.floating__nav--mobile {
  .floating__nav {
    @include list-style;

    >.floating__nav--item,
    .floating__nav--child-item {
      &.isCurrent {

        >.floating__nav--link,
        .floating__nav--child-link {
          color: #4D1B6C;
        }
      }

      >.floating__nav--link,
      .floating__nav--child-link {
        color: #333;
      }
    }

    .floating__nav--count {
      font-family: $Georgia;
      font-size: 14px;
      line-height: 18px;
      color: #333;
      margin-right: 2px;
      text-decoration: none;
    }
  }
}

.download__pdf {
  padding: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn__download-pdf {
    width: 100%;
    max-width: 270px;
  }
}

.authors__block {

  .authors__heading {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 15px;
  }

  .authors__list {
    @include list-style;

    >li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .authors__card {
    display: flex;
    align-items: center;

    .authors__profile {
      width: 82px;
      height: 82px;
      // background: #C4C4C4;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      margin-right: 15px;

      >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 4px;
      }
    }

    .authors__info {
      .authors__name {
        font-family: $Georgia;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #333333;
        margin-bottom: 5px;
      }

      .authors__designation {
        font-family: $Georgia;
        font-weight: 400;
        font-size: 11px;
        color: #333333;
        margin-bottom: 5px;
      }

      .authors__email,
      .authors__phone {
        font-weight: 500;
        font-size: 11px;
        line-height: 12px;
        color: #333333;
        margin-bottom: 5px;

        a {
          color: #333333;
          font-weight: 500;
          text-decoration: underline;
        }
      }
    }
  }
}

.nav__footer {
  display: flex;
  width: 100%;
  background: $purple;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  @media (min-width: 1024px) {
    display: none;
  }

  .nav__footer--item {
    width: 100%;
    border-right: 1px solid #fff;

    &:last-child {
      border-right: 0 solid #fff;
    }

    .nav__footer--btn {
      font-weight: 500;
      font-size: 9px;
      line-height: 10px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #FFFFFF;
      border: 0 solid $purple;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 15px 0 10px;

      .nav__footer--icon {
        margin-bottom: 10px;
      }
    }
  }
}

.scroll__top {
  position: fixed;
  right: 15px;
  bottom: 70px;
  z-index: 10;

  @media (min-width: 1024px) {
    right: 20px;
    bottom: 20px;
  }

  .scroll__top--btn {
    color: $purple;
    background: $white;
    padding: 0;
    border-radius: 50px;
  }
}

.drawer__default {
  .ant-drawer-content {
    background: #F2F2F2;

    .ant-drawer-header {
      display: none;
    }
  }
}

.drawer__default--header {
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 1;
}

// Feed

// Download App
.download__app {
  border-top: 1px solid #DADADA;
  padding: 20px 0;

  &.download__app--dark {
    .download__app--inner {
      background: $purple;
    }

    .download__app--title {
      color: $white;
    }

    .download__app--app-icon {
      color: $purple;
      background: $white;
    }

    .download__app--text {
      color: $white;

      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }

  .download__app--inner {
    background: #F4F4F4;
    border-radius: 8px;
    padding: 20px 15px;
    text-align: center;
  }

  .download__app--title {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #333333;
    text-align: center;
    margin-bottom: 0;
  }

  .download__app--app-icon {
    color: $white;
    background: $purple;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    margin: 10px auto;
  }

  .download__app--text {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #333333;

    a {
      color: $purple;
      text-decoration: underline;
    }
  }
}

// Download App

// Archive
.section__archives {
  padding: 30px 0;
}

.archives__container {
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
}

.archives__list {
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }

  .archives__list--item {
    border-bottom: 1px solid #DADADA;
    margin-bottom: 15px;

    &:last-child {
      border-bottom: 0 solid #DADADA;
    }
  }

  .archives__list--title {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #333333;
  }

  .archives__topics {
    list-style: none;
    font-family: $Georgia;
    padding-left: 0;

    >li {
      font-size: 14px;

      @media (min-width: 768px) {
        font-size: 16px;
      }

      &::marker {
        color: $purple;
      }

      >a {
        font-family: $Georgia;
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
        text-decoration: underline;
        color: #4D1B6C;
        display: inline-flex;
        padding: 5px 0;

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: normal;
        }
      }
    }
  }

  .archives__topics--child {
    list-style: none;
    font-family: $Georgia;
    padding-left: 14px;
    padding-left: 0 !important;

    @media (min-width: 768px) {
      padding-left: 20px;
    }

    >.archives__topic--child-item {
      font-size: 14px;

      @media (min-width: 768px) {
        font-size: 16px;
      }

      &::marker {
        color: $purple;
      }

      >.archives__topic--child-link {
        font-family: $Georgia;
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
        text-decoration: underline;
        color: #4D1B6C;
        display: inline-flex;
        padding: 5px 0;

        @media (min-width: 768px) {
          font-size: 16px;
          line-height: normal;
        }
        .archives-list-number {
          position: relative;
          &::after {
            content: '';
            height: 2px;
            width: 90%;
            background: #ffffff;
            position: absolute;
            top: 15px;
            left: 0;
          }
        }
      }
    }
  }
}
.archives__topics {
  .archives__topic--link {
      .archives-list-img {
        margin-right: 20px;
      }
  }
}
.extrapadding {
  margin-left: 120px;
}
// Archive

// About
.section__team--bio {
  padding: 74px 0 40px;
}

.story__container {
  width: 100%;
  max-width: 810px;
  margin: 0 auto;
}

.story__wrap {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.story__profile {
  width: 200px;
  min-width: 200px;
  height: 188px;
  background: #C4C4C4;
  border: 1px solid #D8D8D8;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 0 auto 20px;

  @media (min-width: 768px) {
    margin-right: 38px;
  }

  >img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 8px;
  }
}

.story__info {

  .story__name {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    color: #4D1B6C;
    text-align: center;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  .story__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #333333;
    text-align: center;
    margin-bottom: 12px;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  .story__bio {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #333333;
  }
}

.story__heading {
  border-bottom: 1px solid #DADADA;
  margin-bottom: 40px;

  .story__heading--title {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #333333;
  }
}

.team__grid {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 80px;
  }

  .team__grid--item {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(100% / 3 - 10px);
      padding: 0 10px;
    }
  }
}

.team__grid--card {
  text-align: center;

  .team__grid--picture {
    width: 120px;
    height: 114px;
    background: #C4C4C4;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .team__grid--info {
    padding: 12px 0;

    .team__name {
      font-family: $Georgia;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #4D1B6C;
      margin-bottom: 10px;
    }

    .team__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #333333;
      text-align: center;
      margin-bottom: 10px;
    }

    .team__bio {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      color: #333333;
    }
  }
}

.section__deals {}

.deals__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .deals__grid--item {
    width: calc(100%);
    margin-bottom: 25px;
  }
}

.deals__grid--card {
  text-align: center;

  .deals__grid--thumb {

    >img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 8px;
    }
  }


}

// About

// Cookies
.cookies-content {
  display: flex;
  flex-direction: column;

  .cookies-content-text {
    font-style: italic;
    padding-bottom: 20px;
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 20px;

    a {
      color: $purple;
      text-decoration: underline;
    }
  }

  .btn__cookies {
    margin-left: auto;
    width: 80px;
    cursor: pointer;
  }
}

// Cookies

// Subscription Center
.subscription-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: $purple url(../images/subscription-bg.svg) no-repeat;
  background-size: auto;
  background-position: calc(100% - 50px) calc(100% - 50px);
}

.subscription-section-wrapper {
  max-width: 700px;
  padding: 0 20px;
  margin: 0 auto;
}

.subscription-section-logo {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    max-width: 400px;
  }
}

.subscription-section-text {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #FFFFFF;
  margin: 40px 0;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 29px;
  }
}

.subscription-section-actions {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-lg {
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
}

// Subscription Center

// Disclaimers
.disclaimers-section {
  padding: 52px 0;
}

.disclaimers-section-wrapper {
  width: 100%;
  max-width: 589px;
  margin: 0 auto;
  padding: 0 15px;
}

.disclaimers-section-text {
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #333333;
  }
}

// Disclaimers

// News
.news-section {
  padding: 70px 0 50px;

  @media (min-width: 1025px) {
    padding: 50px 0;
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1920px;
    }
  }
}

.news-filter-for-mobile {
  position: fixed;
  top: 85px;
  left: 0;
  right: 0;
  z-index: 1;
  max-width: 100%;
  overflow: hidden;

  @media (min-width: 1025px) {
    display: none;
  }

  .btn {
    svg {
      margin-right: 5px;
    }
  }
}

.news-main {
  display: flex;

  .news-left {
    display: none;
    max-height: calc(100vh - 134px);
    overflow: auto;

    @media (min-width: 1025px) {
      display: block;
      flex-basis: 344px;
      border-right: 1px solid #DADADA;
      // padding-right: 40px;
      padding-right: 25px;
    }

    &.sticky-top {
      top: 136px;
      z-index: 10;
    }
  }

  .news-right {
    flex: 1;
    width: 100%;

    @media (min-width: 1025px) {
      padding-left: 40px;
    }
  }
}

.news-left-heading {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #4D1B6C;
  margin-bottom: 32px;
}

.news-topics-wrapper {}

.news-topics-list {
  list-style: none;
  padding: 0;
  margin: 0;

  >li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    .news-topics-count {
      font-weight: 400;
      font-size: 14px;
      line-height: normal;
      text-align: right;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #4D1B6C;
    }
  }
}

.news-view-swicher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 0;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
  margin-bottom: 25px;

  .btn-grid-view,
  .btn-list-view {
    padding: 0 10px;
    color: #979797;

    &.active {
      color: $purple;
    }
  }
}

.news-grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;

  .news-grid-item {
    width: 50%;
    padding: 0 12px;
    margin-bottom: 40px;
    max-width: calc(100% / 1);

    @media (min-width: 900px) {
      max-width: calc(100% / 3);
    }
    @media (min-width: 1199px) {
      max-width: calc(100% / 4);
    }
    @media (min-width: 1299px) {
      max-width: calc(100% / 5);
    }
  }
}

.news-grid-dates {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #333;
  font-family: "Graphik";
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-card {
  .news-thumb {
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    padding-top: 75%;
    position: relative;
    margin-bottom: 15px;

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .news-info {
    .news-subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 20x;
      color: #333333;
      margin-bottom: 10px;
      font-family: $Graphik;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      // min-height: 56px;

      &:first-child {
        color: #4D1B6C;
      }
    }

    .news-heading {
      .news-title {
        font-family: $Georgia;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        text-decoration: underline;
        color: #4D1B6C;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (min-width: 1025px) {
          font-size: 16px;
          line-height: 120%;
        }
      }
    }
  }
}

.news-list {
  display: flex;
  flex-direction: column;

  .news-list-item {
    padding-bottom: 24px;
    margin-bottom: 25px;
    border-bottom: 1px solid #D8D8D8;
    padding-left: 24px;
    padding-right: 24px;

    @media (max-width: 767px) {
      padding-left: 12px;
      padding-right: 12px;
    }

    &:last-child {
      border-bottom: 0 solid #D8D8D8;
    }
  }
}


.no-flex-wrap {
  flex-wrap: nowrap !important;
}

.leftimg-sec {
  padding-top: 0;
  max-width: 229px;
  height: 140px;
  margin-bottom: 0;
  position: relative;
  width: 229px !important;

  @media (max-width:767px) {
    max-width: 100%;
    width: 100% !important;
    height: auto !important;
  }
}

.rightimg-sec {
  width: calc(100% - 229px) !important;
  padding-left: 10px;

  @media (max-width:767px) {
    max-width: 100%;
    width: 100% !important;
    padding-left: 0;
  }

  @media (min-width:1025px) {
    margin-right: 0 !important;
  }
}

.news-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 1025px) {
    flex-direction: row;
  }

  .news-col {
    width: 100%;

    @media (min-width: 1025px) {
      width: 25%;
      margin-right: 30px;
    }
  }

  .news-col-full {
    flex: 1;
    margin-right: 0;
  }

  .news-thumb {
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    padding-top: 56.52%;
    position: relative;
    margin-bottom: 20px;

    @media (min-width: 1025px) {
      padding-top: 0;
      max-width: 229px;
      height: 140px;
      margin-bottom: 0;
    }

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      display: block;

      @media (max-width: 1024px) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .news-info {
    .news-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      margin-bottom: 10px;
      font-family: $Graphik;

      @media (min-width:768px) {
        width: 50%;
      }

      &:first-child {
        color: #4D1B6C;
      }
    }

    .news-heading {
      .news-title {
        font-family: $Georgia;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        text-decoration: underline;
        color: #4D1B6C;
        margin-bottom: 10px;
      }
    }

    .news-desc {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: #333333;
        font-family: $Graphik;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.news-load-more-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    min-width: 272px;
  }
}

// News

// HomeNew
.section-hero {
  padding: 15px 0 0;
  overflow: hidden;

  @media (min-width: 1025px) {
    padding: 45px 0 0;
  }
}

.hero-inner {
  background: #333333;

  @media (min-width: 1025px) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.hero-tab-links {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 7px;
  border-bottom: 1px solid #626262;
  overflow: auto;

  @media (min-width: 1025px) {
    padding: 0 49px;
  }

  .hero-tab-link {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $white;
    padding: 30px 0 25px;
    text-transform: capitalize;
    border-bottom: 4px solid transparent;
    white-space: nowrap;
    margin: 0 10px;

    @media (min-width: 1025px) {
      margin: 0 19px;
      font-size: 18px;
    }

    &.active {
      border-bottom: 4px solid $white
    }
  }
}

.hero-inner-inline-mainbox {
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;

  @media (min-width: 1025px) {
    padding: 60px 68px;
    flex-direction: row;
  }
}

.hero-wrapper {
  width: 100%;

  @media (min-width: 1025px) {
    width: 50%;
  }
}

.hero-info {
  h1 {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #F4F4F2;
    cursor: pointer;
    margin-bottom: 20px;

    @media (min-width: 1025px) {
      font-size: 28px;
      line-height: 120%;
    }
  }

  .hero__editor {
    cursor: pointer;
    margin-bottom: 24px;

    P {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #FFFFFF;

      @media (min-width: 1025px) {
        font-size: 16px;
      }
    }
  }
}

.hero-info-action {
  .btn {
    width: 100%;
    max-width: 254px;
  }
}

.hero-banner {
  position: relative;
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: 1025px) {
    width: 50%;
    right: -150px;
    margin-bottom: 0;
  }

  .hero-banner-thumb {
    position: relative;
    padding-top: 66.66%;
    display: block;

    >img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
}

.section-meet-the-team {
  padding: 66px 0;
}

.meet-the-team-col {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1189px;
  margin: 0 auto;

  @media (min-width: 1025px) {
    flex-direction: row;
  }
}

.meet-the-team-col-left,
.meet-the-team-col-right {
  width: 100%;

  @media (min-width: 1025px) {
    width: 50%;
  }
}

.meet-the-team-quote-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.meet-the-team-quote {
  width: 100%;
  max-width: 552px;
  margin: 0 auto;

  .meet-the-team-quote-title {
    font-family: $Georgia;
    font-style: italic;
    font-weight: 400;
    font-size: 22px;
    line-height: 150%;
    text-align: center;
    color: #333333;
    margin-bottom: 25px;

    @media (min-width: 1025px) {
      font-size: 26px;
      line-height: 150%;
    }
  }

  .meet-the-team-author {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 150%;
    color: #333333;

    @media (min-width: 1025px) {
      font-size: 18px;
    }
  }
}

.meet-the-team-photo {
  width: 100%;
  max-width: 567px;
  margin: 0 auto;

  >img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.meet-the-team-action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 46px;

  .btn {
    width: 100%;
    max-width: 328px;
  }
}

.section-key-news {
  padding: 30px 0;
}

.key-news-heading {
  border-top: 1px solid #D8D8D8;
  padding: 30px 0 0;
  margin-bottom: 27px;

  .key-news-title {
    font-family: $Georgia;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
  }
}

.key-news-column {
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
}

.key-news-column-left,
.key-news-column-right {
  width: 100%;

  @media (min-width: 1024px) {
    width: 50%;
  }
}

.key-news-column-left {
  @media (min-width: 1024px) {
    padding-right: 50px;
    border-right: 1px solid #D8D8D8;
  }
}

.key-news-column-right {
  @media (min-width: 1024px) {
    padding-left: 50px;
  }
}

.key-news-featured-card {}

.key-news-featured-card-thumb {
  position: relative;
  padding-top: 56.52%;
  margin-bottom: 15px;

  >img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.key-news-featured-card-info {
  .key-news-featured-card-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #333333;

  }

  .key-news-featured-card-title {
    font-family: $Georgia;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    text-decoration: underline;
    color: #4D1B6C;
  }
}

.key-news-featured-desc {
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 23px;
    color: #333333;
  }
}

.key-news-list {
  .key-news-list-item {
    border-bottom: 1px solid #D8D8D8;
    margin-bottom: 34px;

    &:last-child {
      margin-bottom: 0;
    }

    .key-news-list-inline {
      display: flex;
      align-items: flex-start;
    }

    .key-news-list-thumb {
      position: relative;
      padding-top: 110px;
      width: 100%;
      max-width: 229px;
      margin-right: 20px;
      margin-bottom: 12px;

      @media (min-width: 1025px) {
        padding-top: 140px;
      }

      >img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .key-news-list-info {
      .key-news-list-subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #333333;
        margin-bottom: 10px;
      }

      .key-news-list-title {
        font-family: $Georgia;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-decoration: underline;
        color: #4D1B6C;

        @media (min-width: 1025px) {
          font-size: 18px;
        }
      }
    }
  }

  .key-news-list-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #333333;

  }
}

.key-news-action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 39px 0 0;

  .btn {
    width: 100%;
    max-width: 328px;
  }
}

// HomeNew

// Footer
.footer {
  padding: 32px 0;
  background: #f4f4f4;
}

.footer__brand {
  margin-bottom: 22px;
}

.footer__row {
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
  }

  .footer__left {
    flex-basis: 30%;
    flex-direction: column;
  }

  .footer__right {
    flex: 1;
    // flex-basis: 66.333%;
  }
}

.footer__info {
  @include list-style;

  @media (max-width: 767px) {
    border-bottom: 1px solid #DADADA;
    margin-bottom: 28px;
  }

  >li {
    margin-bottom: 28px;

    >a {
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      text-decoration: underline;
      color: #4D1B6C;
    }
  }
}

.footer__info--title {
  font-family: $Georgia;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  color: #333333;
  margin-bottom: 12px;
}

.footer__info--subtitle {
  font-family: $Georgia;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 5px;
}

.footer__offices {
  @include list-style;
  padding-top: 12px;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
  }

  >li {
    margin-right: 10px;
    margin-bottom: 22px;

    &:last-child {
      margin-right: 0;
    }

    >a {
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      text-decoration: underline;
      color: #4D1B6C;
    }
  }
}

.footer__bottom {
  padding: 25px 0 0;
  border-top: 1px solid #DADADA;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }

  .footer__copyrights {
    font-weight: 400;
    font-size: 14px;
    color: #333333;

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  .footer__bottom--links {
    @include list-style;

    @media (min-width: 768px) {
      margin-left: 15px;
      display: flex;
      align-items: center;
    }

    >li {
      @media (min-width: 768px) {
        margin-right: 15px;
      }

      >a {
        font-weight: 500;
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 0.5px;
        text-decoration: underline;
        text-transform: uppercase;
        color: #4D1B6C;
      }

    }
  }
}

// Footer

.switch-filter {
  .ant-switch-checked {
    background-color: #4D1B6C;
  }

  .news-view-swicher__label {
    font-weight: 500;
    font-size: 14px;
  }

  .date_label {
    color: #4D1B6C;
  }
}

.purple-text {
  color: purple;
  /* Text color is purple when sortBy is true */
}

.grey-text {
  color: grey;
  /* Text color is grey when sortBy is false */
}

/* Ensure the switch color is purple */
.news-view-swicher__switch {
  background-color: #4D1B6C;
}

.tab-outline {
  background: #FFFFFF;
  border: 1px solid #4D1B6C;
  border-radius: 4px;
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #4D1B6C;
  min-height: 32px;
  min-width: 80px;
  padding: 0 16px;
}

.tab-filled {
  background: #4D1B6C;
  border: 1px solid #4D1B6C;
  border-radius: 4px;
  font-family: 'Graphik';
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #FFFFFF;
  min-height: 32px;
  min-width: 80px;
  padding: 0 16px;
}


.news-heading {
  // margin-bottom: 15px;

  a {
    font-size: 24px;
    line-height: 120%;
    font-weight: 400;
    color: #4D1B6C;

    &:hover {
      color: #4D1B6C;
    }
  }
}

.news--para {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #333;
  margin-bottom: 8px !important;
}

.news--list ul {
  list-style: none;
  padding: 0;

  strong {
    font-weight: normal;
  }
}

.news--block {
  // border-bottom: 1px solid #D8D8D8;
  margin-bottom: 25px;
}

.cursor-auto {
  cursor: auto !important;
}

.news--block p,
li {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #333;
  cursor: pointer;
}

.news--block p {
  font-family: $Graphik;
}

.news-grid-heading {
  // margin-bottom: 15px;
  margin-bottom: 0.5em;

  a {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #4D1B6C;
    line-height: 120%;

    @media (max-width:767px) {
      font-size: 16px;
    }

  }
}

.news-grid-para {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #333;
  font-family: $Graphik;
  margin-bottom: 8px;
}

.news--grid {
  border-radius: 8px;
  background: #F5F5F5;
  padding: 15px;
  margin: 0 6px;
  height: 100%;

  .news--list {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #333;
    font-family: $Graphik;
  }
}

.newslist-description {
  margin-bottom: 1em;
  padding-bottom: 20px;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #333;
    font-family: $Graphik;
  }
}

.GraphicFont {
  font-family: $Graphik;
}

.news-info-block {
  display: flex;
  align-items: start;
  // flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;

  @media (max-width:767px) {
    flex-wrap: wrap;
  }


  .news-subtitle-right {
    white-space: nowrap;
    text-align: right;
  }
}

.purple-font,
.news--block .purple-font {
  color: #4D1B6C;
}

.georgia-font {
  font-family: $Georgia;
}

.title-maxwidth {
  width: calc(100% - 200px);
}


.readmore-anchor {
  color: #000 !important;
}






.news-grid-card {
  border-radius: 8px;
  // background: #F5F5F5;
  background-color: rgba(77, 27, 108, 0.1);
  margin: 0 10px;
  height: 100%;
  // padding: 0 12px;
  padding: 0;
  margin-bottom: 40px;
  // width: calc(33.333% - 20px);
  width: calc(20% - 20px);
  @media (max-width:1199px) {
    width: calc(33.333% - 20px);
  }

  @media (max-width:1024px) {
    width: calc(50% - 20px);
  }

  .content-details {
    padding: 0 12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    place-content: flex-end;
  }

  .news-grid-heading {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    // min-height: 52px;
    line-height: 120%;
    word-break: break-all;
  }

  .news-grid-heading-break {
    word-break : normal;
  }

  .news-grid-heading-full {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    text-overflow: ellipsis;
    // min-height: 52px;
    line-height: 120%;
    margin-bottom: 0.5em;

    @media (max-width:767px) {
      -webkit-line-clamp: 6;
    }

    a {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: #4D1B6C;
      line-height: 120%;

      @media (max-width:767px) {
        font-size: 16px;
      }

    }
  }

  .news-thumb-grid {
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    padding-top: 75%;
    position: relative;
    margin-bottom: 38px;
    @media (max-width:1024px) {
      margin-bottom: 90px;
    }
    @media (max-width:992px) {
      margin-bottom: 60px;
    }
    @media (max-width:767px) {
      margin-bottom: 50px;
    }


    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .news--block {
    padding-left: 0;
    width: 100%;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 20x;
      color: #4D1B6C;
      margin-bottom: 10px;
      font-family: $Graphik;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      // min-height: 56px;

    }
  }

  .news-info {
    .news-subtitle {
      font-weight: 400;
      font-size: 12px;
      line-height: 20x;
      color: #333333;
      margin-bottom: 10px;
      font-family: $Graphik;

      &:first-child {
        color: #4D1B6C;
      }
    }

    .news-heading {
      .news-title {
        font-family: $Georgia;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
        color: #4D1B6C;

        @media (min-width: 1025px) {
          font-size: 18px;
          line-height: 120%;
        }
      }
    }
  }
}




.news-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 1025px) {
    flex-direction: row;
  }

  .news-col {
    width: 100%;

    @media (min-width: 1025px) {
      width: 25%;
      margin-right: 30px;
    }
  }

  .news-col-full {
    flex: 1;
    margin-right: 0;
  }

  .news-thumb {
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    padding-top: 56.52%;
    position: relative;
    margin-bottom: 20px;
    @media (max-width: 767px){
      padding-top: 75%;
    }

    @media (min-width: 1025px) {
      padding-top: 0;
      max-width: 229px;
      height: 140px;
      margin-bottom: 0;
    }

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      display: block;

      @media (max-width: 1024px) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}


.news-row-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @media (min-width: 1025px) {
    flex-direction: row;
  }

  .news-col {
    width: 100%;

    @media (min-width: 1025px) {
      width: 25%;
      margin-right: 30px;
    }
  }

  .news-col-full {
    flex: 1;
    margin-right: 0;
  }

  .news-thumb {
    cursor: pointer;
    width: 100%;
    max-width: 100%;
    padding-top: 56.52%;
    position: relative;
    margin-bottom: 20px;

    @media (min-width: 1025px) {
      padding-top: 0;
      max-width: 229px;
      height: 140px;
      margin-bottom: 0;
    }

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      display: block;

      @media (max-width: 1024px) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .news-info {
    .news-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      margin-bottom: 10px;
      font-family: $Graphik;

      &:first-child {
        color: #4D1B6C;
      }
    }

    .news-heading {
      .news-title {
        font-family: $Georgia;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        text-decoration: underline;
        color: #4D1B6C;
        margin-bottom: 10px;
      }
    }

    .news-desc {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: #333333;
        font-family: $Graphik;
      }
    }
  }
}


.theme-date {
  border-radius: 4px;
  background: #4D1B6C;
  display: inline-flex !important;
  color: #fff;
  padding: 2px 6px;
  font-size: 10px;
  font-weight: 500;
  top: -3px;
  position: relative;
  cursor: default;
}

.default-cursor {
  cursor: default;

}

// list view css open

.listview-themes {
  display: flex;
  // border-bottom: 1px solid #D8D8D8;
  margin-bottom: 24px;
  // background-color: #F5F5F5;
  background-color: rgba(77, 27, 108, 0.1);
  
  padding: 16px 24px;
  border-radius: 8px;
  cursor: pointer;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  @media (max-width: 767px) {
    padding: 16px 12px;
  }



  .news-thumb {
    padding-top: 0;
    max-width: 229px;
    height: 140px;
    margin-bottom: 0;
    position: relative;
    width: 25%;

    @media (max-width: 1024px) {
      cursor: pointer;
      width: 100%;
      max-width: 100%;
      padding-top: 56.52%;
      position: relative;
      margin-bottom: 20px;
    }
    @media (max-width: 767px) {
      padding-top: 75%;
    }
  }


  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}


.news--block {
  width: calc(100% - 25%);
  padding-left: 36px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
    padding-top: 0px;
  }
}

// .news-right {
//   .ant-spin-spinning {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//   }
// }

// .ant-spin-spinning {
//   display: inline-block;
//   opacity: 1;
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// }

.btn.btn-lg.btn__purple {
  .ant-spin-spinning {
    position: static;
    transform: none;
  }
}

// list view css close

.activetab {
  background: #4D1B6C url('../images/checked-btn.svg') no-repeat;
  background-position: 10px center;
  background-repeat: no-repeat;
  background-position: 7px center;
  padding-left: 28px;
}

.content-details {
  height: 185px;
}

.overlay-restrict {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  z-index: 999;
}

.overlay-restrict-none {
  display: none;
}

.filter-label {
  @media (max-width: 980px) {
    display: block;
    width: 100%;
  }
}

.button-flex {
  @media (max-width: 980px) {
    display: flex;
  }

  .ms-4 {
    @media (max-width: 980px) {
      margin-left: 0 !important;
    }
  }
}

.align-btm-mobile {
  margin-bottom: 5px;

  @media (max-width: 980px) {
    align-items: flex-end !important;
  }
}

.pr-0 {
  padding-right: 0 !important;
}

.marg-btm {
  margin-bottom: 5px !important;
}

.custom-flex-wrap {
  // white-space: wrap !important;
  white-space: normal !important;
  // word-break: break-all !important;

  @media (max-width: 767px) {
    text-align: left !important;
  }
}

// unsubscribe-modal css start
.unsubscribe-modal-bg {
  background-color: #f7f7f7 !important;
}
.unsubscribe-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 450px;
  max-width: 96%;
  border-radius: 10px;
  background-color: #fff;
  @media (max-width: 1023px) {
  }
  .custom-header {
      padding-top: 20px;
      padding-left: 24px;
      padding-right: 24px;
  }
  .custom-body {
    padding: 20px 24px;
    .btn {
      padding: 7px 20px;
      border-radius: 4px;
    }
    .btn__border {
      border: 1px solid #ccc;
    }
  }
}
.unsunscribe-section {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
}
.unsubscribe-heading{
  font-size: 28px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  text-align:start;
}
.unsubscrbe-title {
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin: 0 ;
}
.unsubcribe-container {
  background: #fff;
  border-radius: 10px;
  padding: 23px;
  text-align: left;
  margin: 20px auto 0;
}

.unsubscribe-btn {
  padding: 15px 25px;
  text-transform: uppercase;
  margin-top: 25px;
}


// unsubscribe-modal css end

.news-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
}
.news-info-list{
  flex-direction: unset !important;
}
.theme-update {
  background: #4D1B6C;
  padding: 4px 9px 7px 9px;
  border-radius: 2px;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  max-width: 170px;
  @media (max-width: 767px) {
    max-width: 75%;
  }
  img {
    height: auto;
    object-fit: cover;
    width: 100%;
    margin: 0 auto;
  }
}

// user authenticity modals start
.mb-16 {
  margin-bottom: 16px;
}
.header-title {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
.bluebg {
  width: 100%;
  background: #F7FAFC;
  padding: 14px 20px 14px 20px;
  // margin-bottom: 10px;
  .custom-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      text-align: left;
      margin: 0 0 6px 0;
      padding: 0;
      width: 100%;
  }
  .custom-input {
      width: 100%;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #D9DCE1;
      border-radius: 5px;
      padding: 0 10px;
  }

}
.bluebtn {
  padding: 6px 8px;
  background: #4d1b6c;
  border: 1px solid #4d1b6c;
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  margin-right: 20px;
  margin-bottom: 14px;
  margin-top:10px
}
.closebtn2 {
  padding: 6px 8px;
  width: 55px;
  background: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 0px 0px 1px rgba(60, 66, 87, 0.16), 0px 1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3C4257;
  margin-right: 20px;
}
.social-media-modal-ht {
  min-height: 180px !important;
}
.parastyle {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #1A1F36;
}
.email-media-modal{
  .ReactModal__Overlay {
    z-index: 999;
  }
}


// user authenticity modals end

.news-topics-dropdown{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 25px;
  // cursor:pointer;
 >.custom__checkbox{
    font-family: 'Graphik';
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #4D1B6C;
  }
}
.down-arrow-icon{
  max-width: 15px;
  transition: transform 0.3s ease;
}
.news-topics-dropdown .active {
  rotate: 180deg;
}
.news-topics-list .custom__checkbox{
  font-weight: 400;
}

.filter-by-topic-drower {
  .news-topics-wrapper{
    margin-top: 50px;
  }
}
.archives-list-img {
  min-width: 100px;
  max-width: 100px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 12px;
}
.archives-list-number {
  padding-right: 10px;
  padding-left: 10px;
  text-decoration: none;
}
.gated-popoup {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  a {
    text-decoration: underline;
    color: #4d1b6c;
  }
}
.CustomSize{
  .anticon-spin {
    font-size: 16px !important;
  }
}
.MinWidthLogin {
  min-width: 70px;
}
.newslist-description > span > span {
  color: #333; 
  font-weight: 500;
  text-decoration: underline;
}
.news-info-list > div {
  width: 100%;
 }
 @media (max-width: 1240px) {
  .top__navigation--nav > li {
    margin-left: 15px;
}
  .top__navigation--row .top__navigation--right .btn__subscribe {
    min-width: 80px;
    white-space: nowrap;
  }
}