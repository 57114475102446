.sidebarSearch {
  .ant-drawer-title {
    font-family: "Georgia";
    font-weight: 400;
    font-size: 28px;
    line-height: 120%;
    color: #333333;
  }

  .ant-drawer-close {
    opacity: 1;
    width: 20px;
    height: 20px;
    background: url("../../../images/ic-close.svg") no-repeat;
    background-size: 20px;

    svg {
      display: none;
    }
  }

  .search-input {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
  }

  .ant-drawer-content-wrapper {
    max-width: 600px;
    width: 100% !important;
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .ant-drawer-close {
    margin: 0;
  }

  .searchItem {
    display: flex;
    flex-direction: column;
    // gap: 15px;
    gap: 8px;

    .activeList {
      background: #f5f5f5;
      border-radius: 8px;
      padding: 15px;
    }

    .itemList {
      display: flex;
      align-items: start;
      cursor: pointer;
      gap: 12px;
      // margin-bottom: 5px;
      margin-bottom: 0;
      padding: 12px 16px;
      border-radius: 8px;

      .itemImg {
        max-width: 100px;
        height: 70px;
        width: 100%;
        border-radius: 10px;
        background: rgb(251, 251, 251);

        img {
          width: 100%;
          max-width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: cover;
        }
      }

      .itemDetail {
        .itemTitle {
          font-family: "Georgia";
          font-weight: 400;
          font-size: 16px;
          line-height: 120%;
          color: #4d1b6c;
        }

        .itemPara {
          font-family: "Georgia";
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          color: #333333;
          margin-top: 6px;
          margin-bottom: 6px;
        }

      }
    }
  }
}

.loader__small {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.tag-name {
  background: #4d1b6c;
  color: #fff;
  border-radius: 6px;
  padding: 2px 10px 3px 10px;
  margin: 0;
  line-height: normal;
  font-size: 12px;
}

.search-header {
  border: 1px solid #DADADA;
  background: #FFF;
  width: 100%;
  height: 48px;
  color: #333;
  font-family: 'Graphik';
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  /* 18px */
  letter-spacing: 0.5px;
  padding: 0 12px 0 50px;
  background: url("../../../images/ic-search-header.svg") no-repeat;
  background-size: 20px;
  background-position: 14px center;

  &:focus {
    outline: none;
  }
}

.otherDiv.focused {
  border: 1px solid #DADADA;
  background: #FFF;
  width: 500px;
  min-height: 170px;
  padding: 14px;
  position: absolute;
  right: 0;
  top: 0;

  .SearchWeeklies {
    display: block;
  }
}

.otherDiv {
  border: 0px solid #DADADA;
  width: 100%;
  height: auto;
  padding: 0;
}

.SearchWeeklies {
  display: none;

  p {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
    margin: 12px 0 0 0;
  }
}

.dark-title {
  color: #333 !important;
}

.purple-title {
  color: #4D1B6C !important;
}

.see-all-results {
  color: #4D1B6C;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: normal;
  text-decoration-line: underline;
  display: inline-block;
  // width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin: 0 auto;

  &:hover {
    color: #4D1B6C;
    text-decoration-line: underline;
  }
}

.hide-search-results {
  display: none;
}

.itemList-themes {
  background-color: #F5F5F5 !important;
}

.mobile-search {
  display: none;

  @media (max-width: 767px) {
    display: inline;
  }
}

.desktop-search {
  display: flex;
  position: relative;

  @media (max-width: 1023px) {
    display: none;
  }
}

.boldtext {
  font-weight: 800 !important;
}
.profile-gap {
  padding-top: 10px;
  // padding-left: 14px;
  // padding-right: 10px;
}
.top__navigation--dropdown {
  top: 72px !important;
  position: fixed !important;
  .top__navigation--dropdown-link {
    padding: 6px 10px !important;
    color: #F57B8C;
    font-weight: 600;
    &:hover, &:focus {
      color: #F57B8C;
    }
  }
}
// .HideScroll {
//   -webkit-overflow-scrolling: touch;
//   max-height: 100vh;
//     position: fixed;
//     overflow: hidden;
// }

.profileCircle {
  min-width: 40px;
  min-height: 40px;
  background-color: #4D1B6C;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -8px;
  .ProfileName {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin: 0;
    padding: 0;
  }
}
.searchLoaderNew {
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}



