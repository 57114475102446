@import "../../theme/variable.scss";

.border-none {
    border: none !important;
}

.border-global {
    border: 1px solid #D8D8D8;
}
.archives-btn {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    font-weight: 500;
    background: #4D1B6C;
    border: 1px solid #4D1B6C;
    border-radius: 4px;
    // font-family: 'Graphik';
    // font-weight: 500;
    // font-size: 12px;
    // line-height: 13px;
    // letter-spacing: 0.08em;
    // text-transform: uppercase;
    // color: #4D1B6C;
    min-height: 32px;
    min-width: 80px;
    padding: 0 16px;

    &:hover, &:focus, &:active {
        color: #fff;
        background: #4D1B6C;
        border: 1px solid #4D1B6C;
    }
}

.readmorebtn {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    background: #4D1B6C;
    border: 1px solid #4D1B6C !important;
    border-radius: 0;
    font-family: 'Graphik';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    min-height: 60px;
    min-width: 388px;
    padding: 0 16px;
    // display: flex;
    // align-items: center;
    // text-align: center;
    &:hover, &:focus, &:active {
        color: #fff;
        background: #4D1B6C;
        border: 1px solid #4D1B6C !important;
    }
    @media (max-width: 767px) {
        margin: 0 16px;
        width: calc(100% - 32px);
        padding: 0 30px;
    }
}

.top-themes {
    h3 {
        color: #333;
        font-family: Georgia;
        font-size: 22px;
        font-weight: 700;
        line-height: 120%;
        margin: 40px auto 14px;
        overflow: hidden;
        max-width: 100%;
        text-align: center;
    }

    .news-grid {
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
        padding: 40px 0px !important;

        .news-grid-item {
            width: 50%;
            padding: 0 12px;
            margin-bottom: 40px;

            @media (min-width: 1025px) {
                max-width: calc(100% / 3);
            }
        }
    }

    .content-details {
        height: auto;
    }

    .news-grid-card {
        border-radius: 8px;
        background: #fff;
        margin: 0 10px;
        height: 100%;
        padding: 0 12px;
        margin-bottom: 40px;
        width: calc(33.333% - 20px);


        @media (max-width:1024px) {
            width: calc(50% - 20px);
        }

        @media (max-width:767px) {
            width: 100%;
            margin-bottom: 20px;
        }

        .news-grid-heading {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            min-height: 52px;
            word-break:normal;
            @media (max-width:767px) {
                min-height: 20px;
            }
        }

        .news-thumb-grid {
            cursor: pointer;
            width: 100%;
            max-width: 100%;
            padding-top: 67%;
            position: relative;
            margin-bottom: 46px;

            @media (max-width:1024px) {
                margin-bottom: 80px;
              }
              @media (max-width:920px) {
                margin-bottom: 76px;
              }
              @media (max-width:800px) {
                margin-bottom: 70px;
              }
              @media (max-width:767px) {
                margin-bottom: 120px;
              }
              @media (max-width:600px) {
                margin-bottom: 90px;
              }
              @media (max-width:480px) {
                margin-bottom: 76px;
              }
              @media (max-width:414px) {
                margin-bottom: 60px;
              }

            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .news--block {
            padding-left: 0;

            p {
                font-weight: 400;
                font-size: 12px;
                line-height: 20x;
                color: #4D1B6C;
                margin-bottom: 10px;
                font-family: $Graphik;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
                text-overflow: ellipsis;
                // min-height: 56px;

            }
        }

        .news-info {
            .news-subtitle {
                font-weight: 400;
                font-size: 12px;
                line-height: 20x;
                color: #333333;
                margin-bottom: 10px;
                font-family: $Graphik;

                &:first-child {
                    color: #4D1B6C;
                }
            }

            .news-heading {
                .news-title {
                    font-family: $Georgia;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-decoration: underline;
                    color: #4D1B6C;

                    @media (min-width: 1025px) {
                        font-size: 18px;
                        line-height: 120%;
                    }
                }
            }
        }
    }
}