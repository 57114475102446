.footer-message {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #fff;
    padding: 16.5px 24px;
    box-shadow: 0 -1px 10px 0 #acabab4d;
    position: relative;
    z-index: 9999;

    .CookieConsent {
        background-color: #000 !important;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        font-style: italic;

        a {
            text-decoration: underline;
        }
    }

    button {
        color: #FFFFFF !important;
        border-color: #B18100 !important;
        background-color: #B18100 !important;
        font-size: 14px;
        font-family: inherit;
        line-height: 24px;
        padding: 8px 27px !important;
        font-weight: 500;
        margin: 0 8px 0 0;
        border-radius: 2px !important;
        white-space: nowrap;
        cursor: pointer;
        text-align: center;
        text-transform: none;
        min-height: 0;
        font-style: normal;
    }
}