
.social-media-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 450px;
    max-width: 96%;
    min-height: 230px;
    border-radius: 10px;
    background-color: #fff;
    align-items: flex-start;
    margin-left: 225px;
    box-shadow: 0 0 10px #ccc;
    @media (max-width: 1023px) {
        margin-left: 0;
    }
    .custom-header {
        padding-top: 20px;
        padding-left: 24px;
        padding-right: 24px;
    }
}
.social-list {
    list-style: none;
    margin: 0 0 20px 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    li {
        padding: 12px;
        border-radius: 50%;
        margin: 6px;
        img {
            width: 30px;
            height: 30px;
            object-fit: contain;
        }
    }
    .whatsapp {
        background: #25d366;
    }
    .facebook {
        background: #3b5998;
    }
    .twitter {
        background: #000;
    }
    .linkedin {
        background: #0077b5;
    }
    .emails {
        background: #888888;
    }
}
.sharebtn {
    border: 0;
    background-color: transparent;
    padding: 0 0 0 10px;
    img {
        width: 28px;
        height: 28px;
        object-fit: contain;
    }
}
.copy-section {
    display: flex;
    width: calc(100% - 56px);
    padding: 8px 8px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-sizing: border-box;
    margin: 14px 28px 14px;
    .custom-input {
        width: calc(100% - 80px);
        border: 0px solid #ccc;
        border-radius: 0px;
        height: 40px;
        padding-right:10px;
    }
    .custom-input-focus {
        border: 1px solid #000;
    }
    .custom-btn {
        width: 80px;
        background-color: #4D1B6C;
        height: 40px;
        border-radius: 50px;
        border: 0;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer !important;
    }
}
.feed__download_Theme {
    position: relative;
    top: 0;
    img {
        width: 28px;
        height: 28px;
        object-fit: contain;
    }
  }