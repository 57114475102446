
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

.footerlight {
    background-color: #313131;
    padding: 50px 0;
    .left-content {
        img {
            width: 80px;
        }
        p {
            margin-bottom: 1.5rem;
        }
    }
    h2 {
        color: #b18100 !important;
        font-family: 'Roboto';
        font-size: 18px;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 1rem;
        line-height: 2;
        font-weight: 400;
    }
    p {
        font-size: 24px;
        line-height: 30px;
        font-family: 'Merriweather', serif;
        font-weight: 300;
        color: #ffffff;
    }
    .links-tab {
        justify-content: flex-end;
        display: flex;
        @media (max-width:767px) {
            justify-content: flex-start;
            margin-top: 20px;
        }
    }
    .pages{
        p {
            font-family: 'Roboto';
            font-size: 14px;
            line-height: 25px;
            color: #B18100 !important;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
               margin-bottom: 8px;
               font-family: 'Roboto';
                font-size: 18px;
                color: #F0F0F0;
                text-decoration: none;
                cursor: auto;
               a {
                font-family: 'Roboto';
                font-size: 18px;
                color: #F0F0F0;
                text-decoration: none;
                &:hover, &:active {
                    color: #B18100 !important;
                }
               }
           }
   
       }
    }
    .social-icons {
        display: flex;
        align-items: center;
        img {
            width: 20px;
            margin-right: 10px;
            height: 20px;
            object-fit: contain;
            cursor: pointer;
        }
    }
.container {
    @media (max-width:980px) {
        max-width: 100%;
        padding: 0 40px;
    }
    @media (max-width:767px) {
        padding: 0 20px;
    }
}
}