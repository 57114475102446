.search-page {
    max-width: 640px;
    width: 100%;
    margin: 70px auto 0;
    min-height: 80vh;
}

.resultes-for {
    padding-top: 150px;
}

.mb300 {
    margin-bottom: 300px;
}

.boldtext {
    font-weight: 800 !important;
}

// .sidebarSearch .searchItem .itemList:last-child {
//     margin-bottom: 35px;
// }
.sidebarSearch {
    margin-bottom: 70px;
    @media (max-width:1024px) {
        margin-bottom: 0;
    }
}

.itemList-bg {
    background-color: #f5f5f5 !important;
}

.loadmore-list {
    width: 272px;
    margin: 30px auto 0;
}