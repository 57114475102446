.drawer-weekly {
    .ant-drawer-body {
        padding: 0;

        .drawer__default--header {
            position: static;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #dadada;
            margin-bottom: 14px;
            padding: 20px 30px;

            .drawer__default--header--close {
                padding: 0;
            }

            h4 {
                font-family: 'Georgia';
                font-weight: 400;
                font-size: 20px;
                line-height: 120%;
                color: #333333;
                margin: 0;
            }
        }

        .news-topics-wrapper,
        .news-topics-wrapper {
            padding: 14px 20px 14px 30px;

        }

        .custom__checkbox--check {
            // border: 1px solid rgba(0, 0, 0, 0.5);
        }

        .custom__checkbox {
            font-family: 'Georgia';
            font-size: 16px;
        }
    }
}

.graphic-fonts {
    font-family: "Graphik", sans-serif !important;
    font-weight: 500;
    color: #333333;
}

.scrollable-area {
    height: calc(100vh - 90px);
    overflow-y: auto;
}