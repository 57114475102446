.New-Archieve-WeeklyImage {
    border-radius: 20px;
    padding-top: 64%;
    position: relative;
    margin-bottom: 22px;
    img  {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
    } 
}
.feed__header__Archieve {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
    .feed__title {
        font-family: "Georgia";
        font-weight: 400;;
        color: #333333;
        margin-bottom: 0;
        font-size: 28px;
        line-height: 120%;
        @media (max-width: 767px) {
            font-size: 24px;
        }
    }
    .feed__download {

    }
}
.graphik-para {
p {
    color: #333333;
    font-weight: 500;
    font-family: 'Graphik';
    font-size: 14px;
}
}
