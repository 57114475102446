  
// Font Family

// Default
$Arial: Arial, Helvetica, sans-serif;
$CourierNew: 'Courier New', Courier, monospace;
$Georgia: Georgia, serif;
$LucidaSansUnicode: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
$Tahoma: Tahoma, Geneva, sans-serif;
$TimesNewRoman: 'Times New Roman', Times, serif;
$TrebuchetMs: 'Trebuchet MS', Helvetica, sans-serif;
$Verdana: Verdana, Geneva, sans-serif;
// Default

// Custom
$Georgia: 'Georgia';
$Graphik: 'Graphik';
// Font Family


// Color
$purple: #4D1B6C;
$green-logo: #00E1E1;
$green: #4BB543;
$lightgreen: #F7FAFC;
$white: #fff;
$black: #000;
$lightblack: #1A1F36;
$red: #EA1414;
$gray: #333;
$lightgray: #D9DCE1;
// Color


// font Weight
$xlbold: 900;
$exbold: 800;
$bold: 700;
$semibold: 600;
$medium: 500;
$normal: 400;
// font Weight

$base: '../images/';
$fontbase: 1rem;

@mixin list-style {
  list-style: none;
  padding: 0;
  margin: 0;
}

// Max width
$width: 100%;
$maxWidth: 1300px;
// Max width