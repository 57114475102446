@font-face {
    font-family: 'Georgia';
    src: url('Georgia-BoldItalic.woff2') format('woff2'),
        url('Georgia-BoldItalic.woff') format('woff'),
        url('Georgia-BoldItalic.ttf') format('truetype'),
        url('Georgia-BoldItalic.svg#Georgia-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Georgia';
    src: url('Georgia.woff2') format('woff2'),
        url('Georgia.woff') format('woff'),
        url('Georgia.ttf') format('truetype'),
        url('Georgia.svg#Georgia') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Georgia';
    src: url('Georgia_1.woff2') format('woff2'),
        url('Georgia_1.woff') format('woff'),
        url('Georgia_1.ttf') format('truetype'),
        url('Georgia_1.svg#Georgia') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Georgia';
    src: url('Georgia-Italic.woff2') format('woff2'),
        url('Georgia-Italic.woff') format('woff'),
        url('Georgia-Italic.ttf') format('truetype'),
        url('Georgia-Italic.svg#Georgia-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Georgia';
    src: url('Georgia-Bold.woff2') format('woff2'),
        url('Georgia-Bold.woff') format('woff'),
        url('Georgia-Bold.ttf') format('truetype'),
        url('Georgia-Bold.svg#Georgia-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Georgia';
    src: url('Georgia-BoldItalic_1.woff2') format('woff2'),
        url('Georgia-BoldItalic_1.woff') format('woff'),
        url('Georgia-BoldItalic_1.ttf') format('truetype'),
        url('Georgia-BoldItalic_1.svg#Georgia-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

